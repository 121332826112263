import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Benefits from "../../components/ingenieria/benefits";
import PartnersIng from "../../components/ingenieria/partners";
import Info from "../../components/ingenieria/info";
import background from "../../assets/img/background-ing.png";
import backgroundMobile from "../../assets/img/fondo-mobile-ingenieria.png";
import styled from "styled-components";
import Certifications from "../../components/ingenieria/certifications";

const Ingenieria = ({ location }) => (
  <Layout location={location}>
    <SEO title="Ingenieria" />
    <Styled>
      <Info />
      <Benefits />
      <PartnersIng />
      <Certifications />
    </Styled>
  </Layout>
)

const Styled = styled.div`
height: 100%;
min-height: 800px;
display:flex;
flex-direction: column;
align-items: center;
background-image: url("${background}");
background-size: 100vw auto;

@media (min-width: 780px){
  padding-top: 96px;
}

@media (max-width: 600px){
  background-image: url("${backgroundMobile}");
}

.alliances {
  margin-top: 100px;
  width: 100% !important;
}
`

export default Ingenieria