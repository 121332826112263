import React from 'react'
import styled from 'styled-components'
import ContactForm  from './ContactForm'
import { Grid } from '@material-ui/core'

const Info = () => {
  return (
    <Styled>
      <Grid container  
      direction="row"
      justifyContent="center"
      spacing={2} 
      className='grid-container'>
        <Grid  container alignContent="flex-start" xs={12} md={6}>
          <Grid item container justifyContent="flex-start" xs={10} className='logo'>
            <div className='logo-title'>Ingeniería de <br />Software</div>
          </Grid>
          <Grid item container xs={10}>
            <div className='title'>
              El ingenio comienza por entender
            </div>
            <div className='text'>
              Ideamos y desarrollamos software end-to-end personalizado para apoyar la transformación digital de las organizaciones, 
              su necesidad de nuevos productos y los contextos que enfrentan. 
            </div>
            <div className='text'>
              Trabajamos con metodologías ágiles y un equipo de profesionales experimentados en IT para resolver el problema del 
              crecimiento de la demanda de estos perfiles, mucho más alta que la oferta.
            </div>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center" xs={12} md={6}>
          <div className='youtube'>
            <iframe width='544px' height='306px' src="https://www.youtube.com/embed/xMlZuii2or8?si=btU9F6gSymVoao11" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className='iframe-youtube'></iframe>
          </div>
          <div className="contact">
            <ContactForm  page='Ingeniería de Software' location={'Contacto Web'} />
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default Info

const Styled = styled.section`
  max-width: 1200px;
  margin-bottom: 96px;
  @media (max-width: 640px){
    margin-bottom: 48px;
  }

  img {
    @media (max-width: 640px){
      width: 250px;
      height: auto;
    }
  }

  .grid-container {
    width: 100%;
    margin: 0;
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 32px 32px;
    @media (max-width: 640px){
      padding: 0 32px;
    }
  }

  .title {
    color: #19ADAE;
    font-family: "Century Gothic";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    padding-left: 32px;

    @media (max-width: 640px){
      text-align: start;
      padding: 16px 32px 0 32px;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .logo-title {
    color: #000000;
    font-family: "Century Gothic";
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 71px;
    @media (max-width: 640px){
      font-size: 30px;
      line-height: 35px;
      padding-top: 32px;
    }
  }
  
  .text {
    max-width: 100%;
    color: #000000;
    font-family: "Century Gothic";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    padding: 32px 32px 0px 32px;
    @media (max-width: 640px){
      padding: 16px 0 16px 32px;
    }
  }

  .contact {
    max-width: 100%;
    padding: 32px;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;

    @media (max-width: 900px) {
        margin-left: 0px;
    }

    @media (max-width: 780px) {
      margin: 0px 0px 20px 0px;
      padding: 16px;
      width: 100%;
    }

    @media (max-width: 640px){
      h2 {
        font-size: 20px;
      }
      padding: 16px;
    }

    button {
      color: #006069;
      background-color: #6EFFFF;
    }
 }

  .youtube {
    display: flex;
    justify-content: center;
    width: 100%;

  }

  .iframe-youtube {
    margin: 0;
    z-index: 10;
    border-radius: 20px;
    border: 5px solid white;

    @media (max-width: 640px){
      width: 100vw;
      border: none;
      border-radius: 0;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`

