import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import LogoSAFe from '../../assets/img/partners/SAFe.svg'
import LogoScrum from '../../assets/img/partners/Logo_Scrum.svg'
import LogoPMP from '../../assets/img/partners/pmp.png'
import LogoISO from '../../assets/img/partners/Logo_ISO.svg'


const Certifications = () => {
  return (
    <Styled>
      <Grid container  
      direction="row"
      justifyContent="center"
      spacing={0} 
      className='grid-container'>
        <Grid item xs={12} className='certifications'>
          <div align='center' className='title'>
            Certificaciones en Processes & Management
          </div>
        </Grid>
        <Grid item xs={10} className='logos'>
          <div className='grid-logo'>
            <div>
              <LogoSAFe width="280px" height="auto" />
            </div>
            <div>
              <LogoScrum />
            </div>
            <div>
              <img width="183" height="auto" src={LogoPMP} className="img-logo" alt="PMP" />
            </div>
            <div>
              <LogoISO />
            </div>
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default Certifications

const Styled = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  padding-top: 60px;
  padding-bottom: 120px;

  @media screen and (max-width: 600px){
    padding-bottom: 44px;
  }

  .grid-container {
    width: 100%;
    max-width: 1200px;
    margin: 0;
  }

  .logos {
    display: flex;
    justify-content: center;
  }

  .title {
    color: #030144;
    font-family: 'Century Gothic';
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 32px;
    @media screen and (max-width: 600px){
      font-size: 20px;
    }
  }

  .mobile-img {
    display: none;
    @media screen and (max-width: 600px){
      display:block;
    }
  }

  .web-img {
    display: block;
    @media screen and (max-width: 600px){
      display:none;
    }
  }

  .img-logo {
    margin: 0;
  }

  .grid-logo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    div {
      padding: 16px;
      svg {
        max-width: 100%;
      }
    }
    @media screen and (max-width: 600px){
      flex-direction: column;
    }
  }
`
