import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

const Benefits = () => {
  return (
    <Styled>
      <Grid container justifyContent='center' spacing={5}>
        <Grid item container justifyContent="center" xs={12} md={5}>
          <div className='card'>
            <div className='card-title'>Características</div>
            <ul className='dot-list'>
              <li>Desarrollo de aplicaciones personalizadas y productos de software específicos para las necesidades 
                comerciales únicas de cada empresa.</li>
              <li>Experiencia en APIs e integración con otros sistemas, dentro de la empresa o con sistemas externos.</li>
              <li>Trabajamos con metodologías agile y equipos diversificados, inclusivos y multidisciplinarios.</li>
              <li>Alta capacidad de entrega en tiempo, eficiencia, recursos y tecnologías, arquitectura técnica y nube, 
                diseño UX UI y QA&Testing.</li>
              <li>Experiencia en casos específicos y multindustria.</li>
              <li>Desarrollo de inteligencia artificial/aprendizaje automático, experiencia en análisis y BI.</li>
            </ul>
          </div>
        </Grid>
        <Grid item container justifyContent="center" xs={12} md={5}>
          <div className='card'>
            <div className='card-title' align="center">Beneficios</div>
            <ul className='check-list'>
              <li>Reducción de costos gracias a nuestro know how</li>
              <li>Profesionales con habilidades TI experta</li>
              <li>Mejora de la eficiencia y control de los procesos de negocio</li>
              <li>Ejecución  rápida, gracias a las metodologías ágiles</li>
              <li>Enfoque en la necesidad específica, al apropiarnos del problema</li>
              <li>Soluciones con diseño centrado en el usuario y  QA Testing propio</li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default Benefits

const Styled = styled.section`
  max-width: 1200px;
  width: 100%;
  margin-bottom: -48px;

  .card {
    height: fit-content;
    width: 435px;
    padding: 27px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    z-index: 10;
    @media (max-width: 640px){
      margin: 16px;
      padding: 16px;
    }
  }

  .card-title {
    color: #017884;
    text-align: center;
    font-family: "Century Gothic";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }

  .dot-list {
    list-style: none;
    margin-top: 16px;
    color: #000000;
    font-family: "Century Gothic";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;

    li {
      &:before {
        content: "•";
        color: #017884;
        font-weight: bold;
        display: inline-block;
        width: 1.2em;
        margin-left: -1.2em;
      }
    }
  }

  .check-list {
    list-style: none;
    margin: 32px;
    margin-bottom: 32px;
    color: #017884;
    font-family: "Century Gothic";
    font-size: 21px;
    letter-spacing: 0;
    line-height: 28px;

    li {
        &:before {
        content: "✓";
        color: #017884;
        font-weight: bold;
        display: inline-block;
        width: 1.2em;
        margin-left: -1.2em;
        font-family: FontAwesome;
        font-size: 20px;
      }
    }
  }
`
